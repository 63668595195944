<template>
  <div class="content">
    <div class="block content__block--h1">
      <BaseH1 :text="'Finish!'" class="h1 block__h1" />
      <TextDescription
        :text="'Jesteś gwiazdą! Twój profil jest opublikowany!'"
      />
    </div>
    <!-- TODO: Set proper link -->
    <ButtonPrimaryNext :to="{ name: 'ListNanny' }" />
  </div>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext";

export default {
  components: {
    TextDescription,
    ButtonPrimaryNext,
    BaseH1,
  },

  setup() {
    return {};
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 100px;
}
.content__block--h1 {
  width: 360px;
  margin-top: -20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
h1.block__h1:deep() {
  font-size: 72px;
}

@media (max-width: 1200px) {
  .content {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 100px;
  }
  .content__block--h1 {
    width: 100%;
    margin-top: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  h1.block__h1:deep() {
    font-size: var(--h1-short--font-size);
  }
}
</style>
